/* eslint-disable react-hooks/exhaustive-deps */
import React, { CSSProperties, ChangeEvent, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { DatePicker } from "@mui/x-date-pickers";
import {
  Button,
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Tooltip,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import axiosInstance from "../../../../utils/axios/axios.instance";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { convertStringToDate } from "../../../../utils/dateUtil";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { toast } from "react-toastify";
import CustomProgressBar from "../../../../components/CustomProgressBar";
import {
  FundName,
  CompanyName,
  FormDataObject,
} from "../../../../interfaces/updateScript.interface";
import {
  keyOrder,
  constKey,
  FundkeyOrder,
  keyDisplayNames,
  PortfolioKeyNames,
} from "../../../../components/UpdateScript/HelpingFunctions/updateScript";
import PortfolioForm from "../../../../components/UpdateScript/component/ScriptFrom";
import FundFormTable from "../../../../components/UpdateScript/component/FundFormTable";
import {
  portfolioStaticDataFields,
  fundStaticDataFields,
  updateScriptFundData,
  updateScriptPortfolioData,
} from "../../../../components/HelperFunctions/updateScript";
import HistoryIcon from "@mui/icons-material/History";
import InvestmentIcon from "@mui/icons-material/AttachMoney";
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const form = React.createRef<HTMLFormElement>();

function UpdateScriptData() {
  const [fetchTriggered, setFetchTriggered] = useState(false);
  const [selection, setSelection] = React.useState("fund");
  const handleAlignment = (event: any, newAlignment: any) => {
    if (newAlignment !== null) {
      setSelection(newAlignment);
      setIsSelected(false);
      setSelectedValue("");
    }
  };
  const [fundData, setFundData] = useState([]);
  const [isFundUpdate, setisFundUpdate] = useState(false);
  const [isCompanyUpdate, setIsCompanyUpdate] = useState(false);
  const [date, setDate] = useState<Date | null>(new Date());
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [isFundSelected, setFundIsSelected] = useState<boolean>(false);
  const [fundUpdate, setFundUpdate] = useState<boolean>(false);
  const [selectedFundId, setSelectedFundId] = useState<string>("");
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>("");
  const [inputvalue, setInputValue] = useState<any>({});
  const [fundInputValue, setFundInputValue] = useState<any>({});
  const [fundName, setFundName] = useState<string>("");
  const [portfolioList, setPortfolioList] = useState<any[]>([]);
  const [staticValue, setStaticValue] = useState(portfolioStaticDataFields);
  const [fundStaticData, setFundStaticData] =
    useState<any[]>(fundStaticDataFields);
  const [runIDs, setRunIDs] = useState<any[]>([]);
  const [currentRunID, setCurrentRunID] = useState("");
  const [searchParams] = useSearchParams();
  const fundID = searchParams.get("fundID");
  const reportingDate = searchParams.get("reportingDate");
  const selectedRunID = searchParams.get("runID");

  const styles: { [key: string]: CSSProperties } = {
    tableLabel: {
      fontWeight: "bold",
      padding: "10px",
      textAlign: "left",
      fontSize: "15px",
    },
    label: {
      fontWeight: "bold",
      padding: "10px",
      textAlign: "left", // Align the text to the right within the label cell
    },
    input: {
      padding: "10px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      width: "70%", // Make input take full width of its cell
    },
    select: {
      display: "flex", // Make select fill the cell
      backgroundColor: "#f2f2f2", // Background color
      color: "#333", // Text color
      padding: "10px", // Padding
      border: "1px solid #ccc", // Border
      borderRadius: "5px", // Border radius
      outline: "none", // Remove outline when selected
      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Box shadow
      width: "200px", // Width
      cursor: "pointer", // Cursor style
    },
    defaultValue: {
      padding: "10px", // Add padding for aesthetic spacing
      marginLeft: "20px", // Space from the input cell
    },
    buttonContainer: {
      textAlign: "center",
      marginTop: "20px", // Space above the button
    },
    button: {
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
  };
  const theme = createTheme({
    components: {
      MuiToggleButton: {
        styleOverrides: {
          root: {
            "&.Mui-selected, &.Mui-selected:hover": {
              // Styles applied to the button when it is in the selected state
              color: "white",
              backgroundColor: "#1976d2", // A shade of blue
            },
          },
        },
      },
    },
  });
  const [extractedData, setExtractedData] = useState<any[]>([]);
  const [filename, setFileName] = useState<FundName[]>([]);
  const [companyName, setCompanyName] = useState<CompanyName[]>([]);

  useEffect(() => {
    document.title = "AI Extracted Data";
    setDate(reportingDate ? convertStringToDate(reportingDate) : new Date());
    getFileNames();
  }, []);
  useEffect(() => {
    if (fundID && reportingDate && filename.length > 0 && !fetchTriggered) {
      const selectedFund = filename.find(
        (fund: any) => fund.fund_ID === fundID
      );
      if (selectedFund) {
        setSelectedFundId(fundID);
        setFundName(selectedFund.Fund_Name);
        setDate(convertStringToDate(reportingDate || ""));
        setCurrentRunID(selectedRunID || "");

        // Fetch Data
        getPortfolioNames(fundID);
        getCompanyData(selectedRunID || "");
        getRefData2(fundID, convertStringToDate(reportingDate || ""));
        getFundQtrData(convertStringToDate(reportingDate || ""), fundID);

        setFetchTriggered(true); // Prevent further fetching
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fundID, reportingDate, selectedRunID, filename, fetchTriggered]);

  const UpdateCompanyData = async () => {

    try {
      const dateFormat =
        /^(?:(\d{4})-(\d{2})-(\d{2})(?: \d{2}:\d{2}:\d{2})?|(\d{2})-(\d{2})-(\d{4}))$/;

      const { portfolio_exitDate, portfolio_firstInvestmentDate } = inputvalue;

      if (
        (portfolio_exitDate && !dateFormat.test(portfolio_exitDate)) ||
        (portfolio_firstInvestmentDate &&
          !dateFormat.test(portfolio_firstInvestmentDate))
      ) {
        toast.error(
          "Please enter correct date values in DD-MM-YYYY, YYYY-MM-DD format for portfolio exit and first investment dates."
        );
        return; 
      }

      let formData2: FormDataObject = { ...inputvalue };
      delete formData2.BREAK;
      delete formData2._id;
      formData2.fund_ID = selectedFundId;

      const form = new FormData();
      form.append("formData", JSON.stringify(formData2));

      if (isCompanyUpdate) {
        const response = await axiosInstance.patch("/mix-portfolio-data", form);
        if (response.data === "error") {
          toast.error("Error while updating Data.");
        } else {
          setIsSelected(false);
          toast.success("Data updated successfully");
        }
      } else {
        const response = await axiosInstance.post("/mix-portfolio-data", form);
        if (response.data === "error") {
          toast.error("Error while updating Data.");
        } else {
          setIsCompanyUpdate(true);
          setIsSelected(false);
          toast.success("Data updated successfully");
        }
      }
    } catch (err) {
      toast.error("Error while updating Data.");
      console.error(err);
    } finally {
      setTimeout(() => {
        getData(selectedFundId, selectedCompanyId);
      }, 3000);
    }
  };

  const updateFundData = async () => {
    try {
      let formData2: FormDataObject = {};
      delete fundInputValue.BREAK;
      formData2 = fundInputValue;

      // formData2["fund_capitalCalled"] = (
      //   Number(formData2["fund_called"]) -
      //   Number(formData2["fund_recallableDistributions"])
      // ).toString();

      // formData2["fund_valueDistributed"] = (
      //   Number(formData2["fund_distributed"]) -
      //   Number(formData2["fund_recallableDistributions"]) -
      //   Number(formData2["fund_gpCarriedDistribution"])
      // ).toString();
      // formData2["fund_valueNetAsset"] = (
      //   Number(formData2["fund_nav"]) -
      //   Number(formData2["fund_netAssetValueGPCarried"])
      // ).toString();
      const form = new FormData();
      delete formData2._id;
      form.append("fund_ID", fundInputValue["fund_ID"]);
      form.append("Reporting_Date", fundInputValue["Reporting_Date"]);
      form.append("formData", JSON.stringify(formData2));

      if (!isFundUpdate) {
        const response = await axiosInstance.post("/mix-fund-data", form);
        if (response.data === "error") {
          toast.error("Error while updating Data.");
        } else {
          setFundUpdate(true);
          setisFundUpdate(true);
          toast.success("Data updated successfully");
        }
      } else {
        const response = await axiosInstance.patch("/mix-fund-data", form);
        if (response.data === "error") {
          toast.error("Error while updating Data.");
        } else {
          setFundUpdate(true);
          toast.success("Data updated successfully");
        }
      }
    } catch (err) {
      toast.error("Error while updating Data.");
      console.error(err);
    } finally {
      setTimeout(() => {
        getCompanyData();
        setFundUpdate(false);
      }, 3000);
    }
  };
  function areSameDay(d1: Date, d2: Date) {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  }
  const showPdf = async (name: string) => {
    await axiosInstance.get("/getUrlForPdf/" + name).then((res) => {
      setFundPDF(res.data.data);
    });
  };
  const getCompanyData = async (runId?: string) => {
    if (!selectedFundId) {
      alert("Please select Fund first");
      return;
    }
    try {
      const response = await axiosInstance.get(
        "/fund-portfolio-extracted-data/" +
          selectedFundId +
          "/" +
          `${date?.toISOString().split("T")[0].split("-").reverse().join("-")}${
            runId ? `?run_id=${runId}` : ""
          }`
      );
      const FundDataExtracted = response.data.data.filter(
        (item: any) => item.record_type === "fund"
      );
      if (FundDataExtracted.length !== 0) {
        var date1 = date;
        var parts = FundDataExtracted[0].Reporting_Date.split("-");
        var date2 = new Date(parts[2], parts[1] - 1, parts[0]);
        const isSameDay = areSameDay(date1 as Date, date2);

        isSameDay ? setFundData(FundDataExtracted) : setFundData([]);
        if (FundDataExtracted[0]?.pdf_link !== undefined) {
          showPdf(FundDataExtracted[0].pdf_link);
        } else {
          setFundPDF("");
        }
      } else {
        setFundData([]);
      }
      setCompanyName(response.data.data);
      response.data.data != null
        ? setFundIsSelected(true)
        : setFundIsSelected(false);
    } catch (error) {
      console.error(error);
    }
  };
  const [selectedValue, setSelectedValue] = useState("");
  const getData = async (fund_id: string, id: string) => {
    try {
      const midDate = date?.toISOString().split("T")[0];
      const dat = midDate?.split("-").reverse().join("-");
      const response = await axiosInstance.get(
        "/extractedCompanyData/" +
          fund_id +
          "/" +
          id +
          "/" +
          dat +
          "?run_id=" +
          currentRunID
      );
      let newData = { ...response.data.data[0] };
      delete newData.record_type;

      for (const key in newData) {
        if (Array.isArray(newData[key])) {
          if (
            newData[key].length > 0 &&
            typeof newData[key][0] === "object" &&
            !Array.isArray(newData[key][0])
          ) {
            const firstObj = newData[key][0];
            const firstKey = Object.keys(firstObj)[0]; // Get the first key of the first object
            newData[key] = firstObj[firstKey]; // Update the key to the value of the first key in the first object of the array
          } else {
            // If it's not an array of objects, or the array is empty, just use the first element
            newData[key] = newData[key][0] || "";
          }
        }
      }
      setExtractedData(newData);
      response.data.data[0] != null ? setIsSelected(true) : setIsSelected(true);
    } catch (error) {
      console.error(error);
    }
  };
  const getFileNames = async () => {
    try {
      const response = await axiosInstance.get("/extracted-fund-names");
      const responseData = await response.data.data;
      responseData.sort(
        (a: { Fund_Name: string }, b: { Fund_Name: string }) => {
          return a.Fund_Name.toLowerCase().localeCompare(
            b.Fund_Name.toLowerCase()
          );
        }
      );
      setFileName(responseData);
      if (fundID) {
        setSelectedFundId(fundID);
        const selectedFund = responseData.find(
          (fund: any) => fund.fund_ID === fundID
        );
        setFundName(selectedFund.Fund_Name);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePortfolioChanage = (key: string, value: string) => {
    setInputValue((prevInputValue: any) => ({
      ...prevInputValue,
      [key]: value,
    }));
  };
  const handleInputChange = (key: string, value: any) => {
    if (typeof value === "string" && value.includes(",")) {
      const cleanedValue = value.replace(/,/g, "");
      const parsedValue = parseFloat(cleanedValue);
      if (!isNaN(parsedValue)) {
        value = parsedValue;
      }
    }
    setFundInputValue((prevInputValue: any) => ({
      ...prevInputValue,
      [key]: value,
    }));
  };

  const [fundPDF, setFundPDF] = useState<string>("");
  const getFundQtrData = async (ReportingDate: Date, FundID: string) => {
    const midDate = ReportingDate?.toISOString().split("T")[0];
    const converted = midDate?.split("-").reverse().join("-");
    try {
      const response = await axiosInstance.get(
        "/getFundQtr/" + converted + "/" + FundID
      );
      const staticData = await axiosInstance.get("/fund-static/" + FundID);
      const responseData = await response.data.data;
      const fundstaticData = await staticData.data.data;

      if (responseData.length === 0) {
        setFundUpdate(false);
        const data = updateScriptFundData(converted, fundName, FundID);
        const combinedData = {
          ...data[0],
          ...fundstaticData[0],
        };
        setisFundUpdate(false);
        setFundInputValue(combinedData);
      } else {
        const combinedData = {
          ...responseData[0],
          ...fundstaticData[0],
        };
        setisFundUpdate(true);
        setFundInputValue(combinedData);
        setisFundUpdate(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getPortfolioData = async (
    ReportingDate: Date,
    companyID: string,
    companyName: string
  ) => {
    const midDate = ReportingDate?.toISOString().split("T")[0];
    const converted = midDate?.split("-").reverse().join("-");
    try {
      const response = await axiosInstance.get(
        "/portfolio-qtr-review/" +
          converted +
          "/" +
          companyID +
          "/" +
          selectedFundId
      );
      const staticdata = await axiosInstance.get(
        "/portfolio-static/" + companyID
      );
      const responseData = await response.data.data;
      const portfolioStatic = await staticdata.data.data;
      if (responseData.length === 0) {
        const data = updateScriptPortfolioData(
          selectedFundId,
          companyName,
          fundName,
          converted,
          companyID
        );
        const combinedData = {
          ...data[0],
          ...portfolioStatic[0],
        };
        setInputValue(combinedData);
        setIsCompanyUpdate(false);
      } else {
        const combinedData = {
          ...responseData[0],
          ...portfolioStatic[0],
        };
        setInputValue(combinedData);
        setIsCompanyUpdate(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/workflow"); // Navigate to the /workflow page
  };
  const getRefData = async (id: string, date: Date) => {
    try {
      const response = await axiosInstance.get(
        "/company-ref-data/" +
          id +
          "/" +
          date?.toISOString() +
          "/" +
          selectedFundId
      );
      const companyData = response.data.data[0];
      const updatedStaticValue = staticValue.map((item) => {
        Object.keys(item).forEach((key) => {
          if (companyData.hasOwnProperty(key)) {
            item[key as keyof typeof item] = companyData[key];
          }
        });
        return item;
      });

      setStaticValue(updatedStaticValue);
    } catch (error) {
      console.error(error);
    }
  };
  const getRefData2 = async (id: string, ReportingDate: Date) => {
    try {
      const dateReporting = ReportingDate?.toISOString();
      const response = await axiosInstance.get(
        "/fund-ref-data/" + id + "/" + dateReporting
      );
      const companyData = response.data.data[0];
      const updatedStaticValue = fundStaticData.map((item) => {
        Object.keys(item).forEach((key) => {
          if (companyData.hasOwnProperty(key)) {
            item[key as keyof typeof item] = companyData[key];
          }
        });
        return item;
      });
      if (companyData) {
        setFundStaticData(updatedStaticValue);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getPortfolioNames = async (id: string) => {
    try {
      const response = await axiosInstance.get("/getCompanies/" + id);
      const responseData = response.data.data;
      responseData.sort(
        (
          a: { portfolio_companyName: string },
          b: { portfolio_companyName: string }
        ) => {
          return a.portfolio_companyName
            .toLowerCase()
            .localeCompare(b.portfolio_companyName.toLowerCase());
        }
      );
      setPortfolioList(responseData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (fundName) {
      axiosInstance
        .get(
          `/fund-extracted-run-ids/${selectedFundId}/${date
            ?.toISOString()
            .split("T")[0]
            .split("-")
            .reverse()
            .join("-")}`
        )
        .then((response) => {
          setRunIDs(response.data.data);
        });
    }
  }, [fundName, date]);

  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        AI Extracted Data
      </Typography>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/">
            Dashboard
          </Link>
          <Link component={NavLink} to="/workFlow">
            WorkFlow
          </Link>
          <Typography>AI Extraction Update</Typography>
        </Breadcrumbs>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "250px",
            alignItems: "flex-end",
            marginTop: "-30px",
          }}
        >
          <Button
            startIcon={<ArrowBackIcon />}
            variant="contained"
            onClick={handleBack}
            style={{
              marginTop: "auto",
              marginRight: "20px",
              borderRadius: "18px",
              backgroundColor: "#007bff",
              padding: "6px 10px",
              width: "80px",
            }}
          >
            Back
          </Button>
          <CustomProgressBar progress={100} />
        </div>
      </div>

      <Divider my={6} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              {filename && (
                <div style={{ display: "flex" }}>
                  <label
                    style={{
                      marginRight: "10px",
                      marginTop: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Select Fund :
                  </label>
                  <select
                    style={{
                      backgroundColor: "#f2f2f2",
                      marginRight: "20px",
                      color: "#333",
                      padding: "10px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      outline: "none",
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                      width: "250px",
                      cursor: "pointer",
                    }}
                    value={selectedFundId}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      setFundIsSelected(false);
                      setSelection("fund");
                      const selectedIndex = e.target.selectedIndex - 1;
                      if (selectedIndex >= 0) {
                        setSelectedFundId(e.target.value);
                        const date = convertStringToDate(
                          filename[selectedIndex].Reporting_Date
                        );
                        const fundName = filename[selectedIndex].Fund_Name;
                        setFundName(fundName);
                        setDate(date);
                      }
                    }}
                  >
                    <option value="">Please select fund</option>
                    {filename.map((fileName, index) => {
                      return (
                        <option key={index} value={filename[index].fund_ID}>
                          {filename[index].Fund_Name}
                        </option>
                      );
                    })}
                  </select>
                  <DatePicker
                    label="Reporting Date"
                    format="dd/MM/yyyy"
                    value={date}
                    onChange={(newValue) => {
                      setFundIsSelected(false);
                      setDate(newValue);
                    }}
                  />
                  <Button
                    onClick={() => {
                      if (date) {
                        setCurrentRunID("");
                        setIsSelected(false);
                        setCurrentRunID("");
                        getPortfolioNames(selectedFundId);
                        getCompanyData(currentRunID);
                        getRefData2(selectedFundId, date);
                        getFundQtrData(date, selectedFundId);
                      } else {
                        alert("Please select company and date");
                      }
                    }}
                    style={{
                      margin: "5px",
                      marginLeft: "20px",
                      backgroundColor: "#007bff",
                      borderRadius: "18px",
                    }}
                    variant="contained"
                  >
                    Retrieve Data
                  </Button>
                </div>
              )}
              <div style={{ marginTop: "30px" }}>
                {isFundSelected && !fundUpdate && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <label
                      style={{
                        marginRight: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      Select Run ID:
                    </label>

                    <select
                      style={{
                        backgroundColor: "#f2f2f2",
                        marginLeft: "30px",
                        color: "#333",
                        padding: "10px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        outline: "none",
                        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                        width: "280px",
                        height: "52px",
                        cursor: "pointer",
                      }}
                      value={currentRunID}
                      onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                        const selectedIndex = e.target.selectedIndex - 1;
                        if (selectedIndex >= 0) {
                          const selectedID = runIDs.find((id) => {
                            return id.run_id === e.target.value;
                          });
                          setCurrentRunID(selectedID.run_id);
                          getCompanyData(selectedID.run_id);
                        } else {
                          setCurrentRunID("");
                          getCompanyData();
                        }
                      }}
                    >
                      <option value="">Please select Run ID</option>
                      {runIDs.map((runID, index) => (
                        <option key={index} value={runID.run_id}>
                          {runID.run_id}
                        </option>
                      ))}
                    </select>

                    {currentRunID && fundPDF && (
                      <>
                        <a
                          href={fundPDF}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            marginLeft: "20px",
                            color: "#0066cc",
                            textDecoration: "none",
                            fontWeight: "bold",
                          }}
                          type="application/pdf" // This ensures that it's treated as a PDF
                        >
                          View Source PDF
                        </a>
                      </>
                    )}
                  </div>
                )}
              </div>
              <ThemeProvider theme={theme}>
                {isFundSelected && !fundUpdate && (
                  <div>
                    <div style={{ display: "flex", marginTop: "30px" }}>
                      <ToggleButtonGroup
                        style={{ marginLeft: "10px", marginRight: "20px" }}
                        value={selection}
                        exclusive
                        onChange={handleAlignment}
                        aria-label="text alignment"
                      >
                        <ToggleButton value="fund" aria-label="left aligned">
                          Update Fund
                        </ToggleButton>
                        <ToggleButton value="portfolio" aria-label="centered">
                          Update Portfolio
                        </ToggleButton>
                      </ToggleButtonGroup>
                      {selection === "portfolio" && (
                        <>
                          <label
                            style={{
                              marginRight: "10px",
                              marginTop: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            Select Company :
                          </label>
                          <select
                            style={{
                              backgroundColor: "#f2f2f2",
                              marginRight: "20px",
                              color: "#333",
                              padding: "10px",
                              border: "1px solid #ccc",
                              borderRadius: "5px",
                              outline: "none",
                              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                              width: "220px",
                              cursor: "pointer",
                            }}
                            value={selectedValue}
                            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                              if (e.target.value !== "") {
                                const id = e.target.value;
                                setSelectedValue(e.target.value);
                                if (id !== "" && date) {
                                  const selectedCompany = companyName.find(
                                    (company) => company.company_ID === id
                                  );
                                  getPortfolioData(
                                    date,
                                    e.target.value,
                                    selectedCompany?.portfolio_companyName || ""
                                  );
                                  getData(selectedFundId, e.target.value);
                                  getRefData(e.target.value, date);
                                  setSelectedCompanyId(e.target.value);
                                }
                              } else {
                                setSelectedValue("");
                                setIsSelected(false);
                              }
                            }}
                          >
                            <option value="">please select company</option>
                            {portfolioList
                              .filter((key) => {
                                if (
                                  key.hasOwnProperty("portfolio_companyName")
                                ) {
                                  return true;
                                }
                                return false;
                              })
                              .map((company, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={company.company_ID}
                                  >
                                    {company.portfolio_companyName}
                                  </option>
                                );
                              })}
                          </select>
                        </>
                      )}
                    </div>
                    {isSelected && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "20px",
                          marginLeft: "10px",
                        }}
                      >
                        <Tooltip title="Navigate to Historical Data Page to update or add historical information for this fund">
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<HistoryIcon />}
                            onClick={() => {}}
                            style={{
                              marginRight: "10px",
                              borderRadius: "18px",
                            }}
                          >
                            Update Historical Data
                          </Button>
                        </Tooltip>
                        <Tooltip title="Navigate to Investment History Page to update or add investment history for this fund">
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<InvestmentIcon />}
                            onClick={() => {}}
                            style={{ borderRadius: "18px" }}
                          >
                            Update Investment History
                          </Button>
                        </Tooltip>
                      </div>
                    )}
                    {isSelected
                      ? inputvalue && (
                          <div>
                            <div style={styles.buttonContainer}>
                              <Button
                                onClick={() => {
                                  UpdateCompanyData();
                                }}
                                style={{
                                  margin: "20px",
                                  backgroundColor: "#007bff",
                                  borderRadius: "18px",
                                }}
                                variant="contained"
                              >
                                {isCompanyUpdate
                                  ? "Update Portfolio"
                                  : "Add Portfolio"}
                              </Button>
                            </div>
                            <PortfolioForm
                              pdf_link={fundPDF}
                              inputvalue={inputvalue}
                              handlePortfolioChange={handlePortfolioChanage}
                              constKey={constKey}
                              PortfolioKeyNames={PortfolioKeyNames}
                              form={form}
                              staticValue={staticValue}
                              extractedData={extractedData}
                              keyOrder={keyOrder}
                              styles={styles}
                              runId={currentRunID}
                            />
                          </div>
                        )
                      : selection === "fund" &&
                        (fundStaticData.length === 0 ? (
                          <div
                            style={{
                              marginTop: "20px",
                              fontSize: "15px",
                            }}
                          >
                            No Fund Data Found
                          </div>
                        ) : (
                          <div style={styles.buttonContainer}>
                            <Button
                              onClick={() => {
                                updateFundData();
                              }}
                              style={{
                                margin: "20px",
                                backgroundColor: "#007bff",
                                borderRadius: "18px",
                              }}
                              variant="contained"
                            >
                              {isFundUpdate ? "Update Fund" : "Add Fund"}
                            </Button>
                            <FundFormTable
                              form={form}
                              pdf_link={fundPDF}
                              fundStaticData={fundStaticData}
                              fundData={fundData}
                              currentRunID={currentRunID}
                              keyDisplayNames={keyDisplayNames}
                              fundInputValue={fundInputValue}
                              handleInputChange={handleInputChange}
                              constKey={constKey}
                              FundkeyOrder={FundkeyOrder}
                              styles={styles}
                            />
                          </div>
                        ))}
                  </div>
                )}
              </ThemeProvider>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default UpdateScriptData;
