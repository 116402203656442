import React, { useState } from "react";
import TruncateText from "./dialog";
import IconButton from "@mui/material/IconButton";
import { TextField } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PdfViewerModal from "../../add_updateForm/PDFModal";

interface FundFormTableProps {
  form: React.RefObject<HTMLFormElement>;
  fundStaticData: any[];
  fundData: any[];
  currentRunID: string | null;
  keyDisplayNames: { [key: string]: string };
  fundInputValue: { [key: string]: any };
  handleInputChange: (key: string, value: any) => void;
  constKey: string[];
  pdf_link: string;
  FundkeyOrder: string[];
  styles: { [key: string]: React.CSSProperties };
}

const FundFormTable: React.FC<FundFormTableProps> = ({
  form,
  pdf_link,
  fundStaticData,
  fundData,
  currentRunID,
  keyDisplayNames,
  fundInputValue,
  handleInputChange,
  constKey,
  FundkeyOrder,
  styles,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const customSortKeys = (
    keys: string[],
    extractedData: Record<string, any> = {}, // Ensure default to an empty object if undefined
    keyOrder: string[]
  ) => {
    // Filter out the "BREAK" key
    const keysWithoutBreak = keys.filter((key) => key !== "BREAK");

    // Get keys that should appear before the "BREAK"
    const beforeBreak = keysWithoutBreak.filter(
      (key) =>
        keyOrder.includes(key) &&
        keyOrder.indexOf(key) < keyOrder.indexOf("BREAK")
    );

    // Get keys that should appear after the "BREAK"
    const afterBreak = keysWithoutBreak.filter(
      (key) =>
        keyOrder.includes(key) &&
        keyOrder.indexOf(key) >= keyOrder.indexOf("BREAK")
    );

    // Sorting function for a section (either before or after "BREAK")
    const sortSection = (sectionKeys: string[]) => {
      return sectionKeys.sort((a, b) => {
        // Ensure that extractedData exists and has the key
        const hasValueA = extractedData?.[a] ? 1 : 0;
        const hasValueB = extractedData?.[b] ? 1 : 0;

        // Sort by whether they have values first (prefer keys with values)
        if (hasValueA !== hasValueB) {
          return hasValueB - hasValueA;
        }

        // If both have values or both don't, sort based on their order in keyOrder
        return keyOrder.indexOf(a) - keyOrder.indexOf(b);
      });
    };

    // Sort the keys before and after the "BREAK" separately
    const sortedBeforeBreak = sortSection(beforeBreak);
    const sortedAfterBreak = sortSection(afterBreak);

    // Return the final sorted array, inserting "BREAK" in the middle
    return [...sortedBeforeBreak, "BREAK", ...sortedAfterBreak];
  };

  return (
    <form
      ref={form}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div>
        <table
          style={{
            textAlign: "left",
            width: "100%", // White background
            borderCollapse: "separate", // Allows the borders to be separated for spacing
            borderSpacing: "1px", // Sets the space between the cells
          }}
        >
          <thead>
            <tr>
              <td colSpan={5}>
                <div
                  style={{
                    margin: "20px 0",
                    padding: "10px",
                    borderBottom: "2px solid #ccc",
                    backgroundColor: "#f9f9f9", // Light background color
                    textAlign: "center", // Center the title
                  }}
                >
                  <h3
                    style={{
                      margin: "0",
                      padding: "0",
                      color: "#333",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    Dynamic Content
                  </h3>
                </div>
              </td>
            </tr>
            <tr>
              <th
                style={{
                  ...styles.tableLabel,
                  width: "20%", // Set width to 25%
                }}
              >
                Key name
              </th>
              <th
                style={{
                  ...styles.tableLabel,
                  textAlign: "center",
                  width: "30%", // Set width to 25%
                  marginLeft: "20px",
                }}
              >
                Update value manually
              </th>
              <th
                style={{
                  ...styles.tableLabel,
                  textAlign: "center",
                  width: "25%", // Set width to 25%
                }}
              >
                AI extracted value
              </th>
              <th
                style={{
                  ...styles.tableLabel,
                  textAlign: "center",
                  width: "10% !important", // Set width to 25%
                }}
              >
                Page Number
              </th>
              <th
                style={{
                  ...styles.tableLabel,
                  color: "grey",
                  textAlign: "center",
                  width: "25%", // Set width to 25%
                }}
              >
                Previous Entry
              </th>
            </tr>
          </thead>
          <tbody>
            {customSortKeys(
              Object.keys(fundStaticData[0]).filter(
                (key) =>
                  key !== "_id" &&
                  key !== "created_At" &&
                  key !== "run_id" &&
                  key !== "record_type" &&
                  key !== "fund_ID" &&
                  !key.includes("pageNumber")
              ),
              fundData[0],
              FundkeyOrder
            ).map((key, index) => {
              const file = currentRunID
                ? (fundData[0] as { [key: string]: any })
                : {};
              const reference = fundStaticData[0] as { [key: string]: any };
              if (key === "_id") return null;
              if (key === "BREAK")
                return (
                  <>
                    <tr>
                      <td colSpan={5}>
                        <div
                          style={{
                            margin: "20px 0",
                            padding: "10px",
                            borderBottom: "2px solid #ccc",
                            backgroundColor: "#f9f9f9", // Light background color
                            textAlign: "center", // Center the title
                          }}
                        >
                          <h3
                            style={{
                              margin: "0",
                              padding: "0",
                              color: "#333",
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            Static Content
                          </h3>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th
                        style={{
                          ...styles.tableLabel,
                          width: "20%", // Set width to 25%
                        }}
                      >
                        Key name
                      </th>
                      <th
                        style={{
                          ...styles.tableLabel,
                          textAlign: "center",
                          width: "30%", // Set width to 25%
                          marginLeft: "20px",
                        }}
                      >
                        Update value manually
                      </th>
                      <th
                        style={{
                          ...styles.tableLabel,
                          textAlign: "center",
                          width: "25%", // Set width to 25%
                        }}
                      >
                        AI extracted value
                      </th>
                      <th
                        style={{
                          ...styles.tableLabel,
                          textAlign: "center",
                          width: "10% !important", // Set width to 25%
                        }}
                      >
                        Pages
                      </th>
                      <th
                        style={{
                          ...styles.tableLabel,
                          color: "grey",
                          textAlign: "center",
                          width: "25%", // Set width to 25%
                        }}
                      >
                        Previous Entry
                      </th>
                    </tr>
                  </>
                );
              else {
                return (
                  <tr
                    key={index}
                    style={{
                      paddingTop: "30px",
                      borderBottom: "1px solid #cccccc",
                      backgroundColor: index % 2 === 0 ? "#f2f2f2" : "#FFFFFF",
                    }}
                  >
                    <td
                      style={{
                        ...styles.label,
                        paddingBottom: "10px",
                        width: "20%",
                      }}
                    >
                      {keyDisplayNames[key] || key.replace(/_/g, " ")}
                    </td>
                    <td
                      style={{
                        padding: "5px",
                      }}
                    >
                      <TextField
                        key={key}
                        name={key}
                        id={index.toString()}
                        multiline
                        minRows={1}
                        maxRows={6}
                        style={{
                          width: "100%", // Make input take full width of its cell
                          textAlign: "justify",
                          marginBottom: "10px",
                          background: "#fff", // Adds a white background
                          borderColor: "#007bff", // Blue border color
                          borderWidth: "2px", // Border width
                          borderRadius: "4px", // Rounded corners
                        }}
                        value={fundInputValue[key]}
                        type="text"
                        onChange={(e: { target: { value: any } }) => {
                          handleInputChange(key, e.target.value);
                        }}
                        disabled={constKey.includes(key)}
                      />
                    </td>

                    {file &&
                    (typeof file[key] === "string" ||
                      typeof file[key] === "number") &&
                    file[key] !== "" ? (
                      <>
                        <td
                          style={{
                            ...styles.label,
                            paddingBottom: "10px",
                            textAlign: "center",
                            width: "25%", // Set width to 25%
                          }}
                        >
                          <TruncateText text={file[key]} maxWords={40} />
                          {!constKey.includes(key) && (
                            <IconButton
                              aria-label="content copy"
                              size="small"
                              onClick={() => handleInputChange(key, file[key])}
                            >
                              <ContentCopyIcon fontSize="inherit" />
                            </IconButton>
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            width: "10% !important", // Set width to 25%
                          }}
                          onClick={() => {
                            if (fundData[0].page && key in fundData[0].page) {
                              setPageNumber(fundData[0].page[key]);
                              window.open(
                                `${pdf_link}#page=${fundData[0].page[key]}&toolbar=0`,
                                "_blank"
                              );
                            }
                          }}
                        >
                          {fundData[0].page && key in fundData[0].page ? (
                            <p
                              style={{
                                cursor: "pointer",
                                color: "blue",
                                textDecoration: "underline",
                              }}
                            >
                              {fundData[0].page[key]}
                            </p>
                          ) : (
                            <p>-</p>
                          )}
                        </td>
                      </>
                    ) : (
                      <>
                        <td
                          style={{
                            textAlign: "center",
                            width: "10% !important", // Set width to 25%
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            width: "10% !important", // Set width to 25%
                          }}
                        >
                          -
                        </td>
                      </>
                    )}

                    <td
                      style={{
                        ...styles.label,
                        paddingBottom: "10px",
                        textAlign: "center",
                        color: "grey",
                        width: "25%", // Set width to 25%
                      }}
                    >
                      {reference[key] === "" ? (
                        "-"
                      ) : (
                        <TruncateText text={reference[key]} maxWords={40} />
                      )}
                      {reference[key] !== "" && !constKey.includes(key) && (
                        <IconButton
                          aria-label="content copy"
                          size="small"
                          onClick={() => handleInputChange(key, reference[key])}
                        >
                          <ContentCopyIcon fontSize="inherit" />
                        </IconButton>
                      )}
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
      <div>
        <PdfViewerModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          pdfUrl={pdf_link} // Replace with your actual PDF file URL
          pageNumber={pageNumber}
        />
      </div>
    </form>
  );
};

export default FundFormTable;
