import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  TextField,
  Typography,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Button,
} from "@mui/material";
import axiosInstance from "../../utils/axios/axios.instance";
import { IPortfolioStatic } from "../../interfaces/portfolioStatic.interface";
import PortfolioSelectionComponent from "../portfolioSelection";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { NavLink } from "react-router-dom";
import { IFundStatic } from "../../interfaces/fundStatic.interface";
import { set } from "date-fns";
import { useGlobalState } from "../../contexts/GlobalStateContext";
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Divider = styled(MuiDivider)(spacing);
interface RowData {
  [key: string]: string | number | null; // Assuming all values are either string or number for simplicity
}

interface TableData {
  [key: string]: any;
}
interface FundTableProps {
  data: TableData[];
}

const PortfolioReviewTable: React.FC<FundTableProps> = (props) => {
  const [firstRow, setFirstRow] = useState<RowData[]>([]);
  const [isSelected, setIsSelected] = useState(false);
  const [fundNames, setFundNames] = useState<Record<string, number>[]>([]);
  const { lpName } = useGlobalState();

  // const [isdata, setIsData] = useState(false);
  const [portfolioNames, setPortfolioNames] = useState<
    Record<string, number>[]
  >([]);
  const [selectionData, setSelectionData] = useState({
    fund_ID: "",
    company_ID: "",
    date: "",
  });

  useEffect(() => {
    getFundNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lpName]);

  const getPortfolioNames = async (id: string) => {
    try {
      const response = await axiosInstance.get("/getCompanies/" + id);
      const responseData = response.data.data;

      // Map the portfolio names and sort them alphabetically by company name
      const portfolioNames = responseData
        .map((portfolio: IPortfolioStatic) => {
          return {
            [portfolio.portfolio_companyName as string]: portfolio.company_ID,
          };
        })
        .sort((a: { [key: string]: string }, b: { [key: string]: string }) => {
          const nameA = Object.keys(a)[0].toLowerCase();
          const nameB = Object.keys(b)[0].toLowerCase();
          return nameA.localeCompare(nameB);
        });

      // Check if portfolioNames is a valid array and handle the selection
      if (
        Array.isArray(portfolioNames) &&
        portfolioNames.length > 0 &&
        typeof portfolioNames[0] === "object" &&
        portfolioNames[0] !== null
      ) {
        const values = Object.values(portfolioNames[0]) as number[];
        if (values.length > 0) {
          setSelectionData({
            fund_ID: id,
            company_ID: values[0].toString(),
            date: "31-03-2021",
          });
        } else {
          console.error("First object in the array is empty.");
        }
      } else {
        console.error("portfolioNames is not a valid array of objects.");
      }

      // Store the sorted portfolio names in the state
      setPortfolioNames(portfolioNames);
    } catch (error) {
      console.error(error);
    }
  };

  const getFundNames = async () => {
    try {
      const response = await axiosInstance.get("/fund-names/" + lpName);
      const responseData = response.data.data;

      // Map the fund names and sort them alphabetically
      const fundNames = responseData
        .map((fund: IFundStatic) => {
          return { [fund.Fund_Name]: fund.fund_ID };
        })
        .sort((a: { [key: string]: string }, b: { [key: string]: string }) => {
          const nameA = Object.keys(a)[0].toLowerCase();
          const nameB = Object.keys(b)[0].toLowerCase();
          return nameA.localeCompare(nameB);
        });
      setFundNames(fundNames);

      if (fundNames.length > 0) {
        const firstFundId = Object.values(fundNames[0])[0] as string;
        getPortfolioNames(firstFundId);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (value: string, idx: number, key: string) => {
    const updatedRows = [...firstRow];
    updatedRows[idx][key] = value;
    setFirstRow(updatedRows); // Set the new state
  };
  const handleSelectionChange = (data: {
    fund_ID: string;
    company_ID: string;
    date: string;
  }) => {
    if (data.fund_ID !== selectionData.fund_ID && data.fund_ID !== "") {
      getPortfolioNames(data.fund_ID);
    }
    setSelectionData(data);
  };
  const validateDateFormat = (value: string): boolean => {
    const dateRegex = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/;
    return dateRegex.test(value);
  };

  const PostDataToServer = async () => {
    const historyDates = firstRow.find((row) => row.Heading === "History Date");
    if (!historyDates) {
      toast.error("No History Date row found.");
      return;
    }
    for (let i = 1; i <= 9; i++) {
      const dateField = historyDates[`Fields-${i}`];
      if (dateField && !validateDateFormat(dateField.toString())) {
        toast.error(
          `Invalid date format in Fields-${i}. Please use dd-mm-yyyy.`
        );
        return;
      }
    }

    try {
      setIsSelected(false);
      let reversedData = [];
      for (let i = 1; i <= 9; i++) {
        const dateField = historyDates[`Fields-${i}`];
        if (!dateField) continue;
        const entry: any = {
          Reporting_Date: String(dateField), // Convert to string explicitly
          portfolio_series: "", // Default to empty string
          portfolio_investedbyGP: 0, // Default to 0
          portfolio_finalsizeRound: 0, // Default to 0
          portfolio_postmoneyval: 0, // Default to 0
          portfolio_InvCurrency: "", // Default to empty string
        };

        firstRow.forEach((row) => {
          const fieldValue = row[`Fields-${i}`];
          switch (row.Heading) {
            case "Series":
              entry.portfolio_series =
                fieldValue != null && fieldValue !== ""
                  ? String(fieldValue)
                  : "";
              break;
            case "Invested by GP":
              entry.portfolio_investedbyGP =
                fieldValue != null && fieldValue !== ""
                  ? parseFloat(fieldValue as string)
                  : 0;
              break;
            case "Final size of round":
              entry.portfolio_finalsizeRound =
                fieldValue != null && fieldValue !== ""
                  ? parseFloat(fieldValue as string)
                  : 0;
              break;
            case "Post money valuation":
              entry.portfolio_postmoneyval =
                fieldValue != null && fieldValue !== ""
                  ? parseFloat(fieldValue as string)
                  : 0;
              break;
            case "Currency":
              entry.portfolio_InvCurrency =
                fieldValue != null ? String(fieldValue) : "";
              break;
            default:
              break;
          }
        });

        reversedData.push(entry);
      }

      const form = new FormData();
      form.append("company_ID", selectionData.company_ID);
      form.append("fund_ID", selectionData.fund_ID);
      form.append("formData", JSON.stringify(reversedData));
      form.append("LP_name", "TC2009");
      const response = await axiosInstance.put("/portfolio-investment", form);
      if (response.status === 200) {
        toast.success("Portfolio review data updated successfully!");
      } else {
        toast.error("Failed to update portfolio review data.");
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to update portfolio review data.");
    } finally {
      setTimeout(() => {
        getPortfolioReviewData();
      }, 1500);
    }
  };

  const getPortfolioReviewData = async () => {
    try {
      const response = await axiosInstance.get(
        `portfolio-investment/${selectionData.fund_ID}/${selectionData.company_ID}/${selectionData.date}/TC2009`
      );
      const responseData = response.data.data;
      const formattedData: { [key: string]: (string | number | null)[] } = {
        "History Date": [],
        Series: [],
        "Invested by GP": [],
        "Final size of round": [],
        "Post money valuation": [],
        Currency: [],
      };

      responseData.forEach((item: any) => {
        formattedData["History Date"].push(item.Reporting_Date);
        formattedData.Series.push(item.portfolio_series);
        formattedData["Invested by GP"].push(item.portfolio_investedbyGP);
        formattedData["Final size of round"].push(
          item.portfolio_finalsizeRound
        );
        formattedData["Post money valuation"].push(item.portfolio_postmoneyval);
        formattedData.Currency.push(item.portfolio_InvCurrency);
      });
      const updatedRows = Object.keys(formattedData).map((heading) => {
        const fields = formattedData[heading];
        return {
          Heading: heading,
          "Fields-1": fields[0] || "",
          "Fields-2": fields[1] || "",
          "Fields-3": fields[2] || "",
          "Fields-4": fields[3] || "",
          "Fields-5": fields[4] || "",
          "Fields-6": fields[5] || "",
          "Fields-7": fields[6] || "",
          "Fields-8": fields[7] || "",
          "Fields-9": fields[8] || "",
        };
      });
      setIsSelected(true);
      setFirstRow(updatedRows);
    } catch (error) {
      setIsSelected(false);
      console.error(error);
    }
  };

  const renderTableContent = () => {
    return (
      <div>
        <div style={{ display: "flex" }}>
          <div>
            <PortfolioSelectionComponent
              fundNames={fundNames}
              portfolioNames={portfolioNames}
              onSelectionChange={handleSelectionChange}
            />
          </div>
        </div>
        <div>
          <Button
            onClick={getPortfolioReviewData}
            style={{
              margin: "5px",
              marginLeft: "20px",
              marginBottom: "20px",
              borderRadius: "18px",
              backgroundColor: "#007bff",
            }}
            variant="contained"
          >
            Get Data
          </Button>
          {/* <button onClick={getPortfolioReviewData}>get data</button> */}
        </div>
        {isSelected && (
          <div
            style={{
              padding: "10px",
            }}
          >
            <TableBody>
              {firstRow.map((item, idx) => (
                <TableRow key={idx}>
                  <TableCell
                    style={{
                      backgroundColor: idx % 2 === 0 ? "#FFFFFF" : "#EFF5FF",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "200px",
                    }}
                  >
                    <Typography variant="subtitle2">
                      {" "}
                      {item.Heading}{" "}
                    </Typography>
                  </TableCell>
                  {Object.entries(item).map(([key, value], index) => {
                    if (key === "Heading") return null;
                    return (
                      <TableCell
                        key={index}
                        style={{
                          backgroundColor:
                            idx % 2 === 0 ? "#FFFFFF" : "#EFF5FF",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "200px",
                          textAlign: index !== 0 ? "center" : "left",
                        }}
                      >
                        <TextField
                          fullWidth
                          variant="standard"
                          value={value != null ? value.toString() : ""}
                          InputProps={{
                            style: {
                              textAlign: "center",
                              alignItems: "center", // This only works if height is set or in a flex container
                              display: "flex", // Ensures vertical centering
                            },
                          }}
                          onChange={(e) =>
                            handleInputChange(e.target.value, idx, key)
                          }
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <Helmet title="Portfolio Review" />
      <Typography variant="h3" gutterBottom display="inline">
        Portfolio Review Form
      </Typography>
      <div
        style={{
          marginTop: "0px",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/">
            Dashboard
          </Link>
          <Link component={NavLink} to="/workFlow">
            WorkFlow
          </Link>
          <Typography>Portfolio Review Update</Typography>
        </Breadcrumbs>
      </div>
      <Divider my={6} />
      <Paper style={{ width: "100%" }}>
        <Table stickyHeader>{renderTableContent()}</Table>
        {isSelected && (
          <div
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              paddingBottom: "30px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              // onClick={PostDataToServer}
              onClick={PostDataToServer}
              style={{
                margin: "5px",
                marginLeft: "20px",
                backgroundColor: "#007bff",
                borderRadius: "18px",
              }}
              variant="contained"
            >
              Update
            </Button>
          </div>
        )}
      </Paper>
    </div>
  );
};

export default PortfolioReviewTable;
